import loadable from '@loadable/component';
import { pagesType, sidebarMenuRoutes, sidebarMenuTranslations } from './utils/enums';
import { CreateParcelleCadastrale } from './components/pages/parcelle-cadastrale/CreateParcelleCadastrale';
import { CreateUploadDocument } from './components/pages/document/CreateUploadDocument';
import { lazyWithRetry } from './components/core/LazyWithRetry';

const Dashboard = lazyWithRetry(() => import('./components/pages/Dashboard'));
const Organization = lazyWithRetry(() => import('./components/pages/Organization'));
const SubContractors = lazyWithRetry(() => import('./components/pages/SubContractors'));
const Contracts = lazyWithRetry(() => import('./components/pages/Contracts'));
const Customers = lazyWithRetry(() => import('./components/pages/Customers'));
const Quotations = lazyWithRetry(() => import('./components/pages/Quotations'));
const Invoices = lazyWithRetry(() => import('./components/pages/Invoices'));
const DelegateeInvoices = lazyWithRetry(() => import('./components/pages/DelegateeInvoices'));
const DelegateeInvoice = lazyWithRetry(() => import('./components/pages/delegatee_invoices/DelegateeInvoice'));
const DelegateeInvoiceEdition = lazyWithRetry(() => import('./components/pages/delegatee_invoices/DelegateeInvoiceEdition'));
const DelegateeInvoicePreview = lazyWithRetry(() => import('./components/pages/delegatee_invoices/DelegateeInvoicePreview'));
const Invoice = lazyWithRetry(() => import('./components/pages/quotation/Quotation'));
const InvoiceEdition = lazyWithRetry(() => import('./components/pages/invoice/InvoiceEdition'));
const InvoicePreview = lazyWithRetry(() => import('./components/pages/invoice/InvoicePreview'));
const Documents = lazyWithRetry(() => import('./components/pages/Documents'));
const Planning = lazyWithRetry(() => import('./components/pages/Planning'));
const SubContractor = lazyWithRetry(() => import('./components/pages/SubContractor'));
const Profile = lazyWithRetry(() => import('./components/pages/Profile'));
const Customer = lazyWithRetry(() => import('./components/pages/Customer'));
const CreateCustomerPP = lazyWithRetry(() => import('./components/pages/customer/CreateCustomerPP'));
const Quotation = lazyWithRetry(() => import('./components/pages/quotation/Quotation'));
const QuotationEdition = lazyWithRetry(() => import('./components/pages/quotation/QuotationEdition'));
const QuotationPreview = lazyWithRetry(() => import('./components/pages/quotation/QuotationPreview'));
const Document = lazyWithRetry(() => import('./components/pages/Document'));
const ProductCatalogue = lazyWithRetry(() => import('./components/pages/ProductCatalogue'));
const Product = lazyWithRetry(() => import('./components/pages/Product'));
const Contract = lazyWithRetry(() => import('./components/pages/Contract'));
const NoAccess = lazyWithRetry(() => import('./components/pages/NoAccess'));
const MailsHistory = lazyWithRetry(() => import('./components/pages/MailsHistory'));
const Maintenance = lazyWithRetry(() => import('./components/pages/Maintenance'));
const Prime = lazyWithRetry(() => import('./components/pages/prime/Prime'));
const PrimeFolders = lazyWithRetry(() => import('./components/pages/dossiers/PrimeFolders'));

const PublicDossierCEE = loadable(() => import('./components/pages/PublicDossierCEE'));
const SignedDocument = loadable(() => import('./components/pages/SignedDocument'));
const Signature = loadable(() => import('./components/pages/Signature'));
const CreditFinancingConsentRequest = loadable(() => import('./components/pages/credit_financing/CreditFinancingConsentRequest'));
const CreditFinancingConsentAccepted = loadable(() => import('./components/pages/credit_financing/CreditFinancingConsentAccepted'));
export const UUID_URL_PARAMETER = '/:uuid';
export const DOSSIER_UUID_URL_PARAMETER = '/:dossierUUID';
export const UPLOAD_DOCUMENT_UUID_URL_PARAMETER = '/:uploadDocumentUUID';
export const DOSSIERS_PAGE_URL = '/dossiers-financement';
export const DOCUMENT_CHECK_REPORT_PAGE_URL = '/verification-document';
export const ORGANIZATION_PAGE_URL = '/organisation';
export const DASHBOARD_PAGE_URL = '/tableau-de-bord';
export const QUOTATIONS_PAGE_URL = '/devis';
export const PLANNING_PAGE_URL = '/planning';

const privateRoutes = [
    {
        label: 'Tableau de bord',
        path: '/tableau-de-bord',
        name: pagesType.DASHBOARD,
        icon: 'dashboard',
        component: Dashboard,
        isMenuItem: true,
    },
    {
        label: 'Organisation',
        path: '/organisation',
        name: pagesType.ORGANIZATION,
        icon: 'organization',
        component: Organization,
        isMenuItem: true,
    },
    {
        label: 'Sous-traitants',
        name: pagesType.SUB_CONTRACTORS,
        path: '/sous-traitants',
        icon: 'subcontractors',
        component: SubContractors,
        exact: true,
        isMenuItem: true,
    },
    {
        label: 'Fiche contrat',
        path: '/contrats/:uuid',
        component: Contract,
        name: pagesType.CONTRACTS,
    },
    {
        label: sidebarMenuTranslations.CONTRACTS,
        path: sidebarMenuRoutes.CONTRACTS,
        name: pagesType.CONTRACTS,
        icon: 'contracts',
        component: Contracts,
        isMenuItem: true,
    },

    {
        label: 'Clients',
        path: '/clients',
        icon: 'customers',
        name: pagesType.CUSTOMERS,
        component: Customers,
        exact: true,
        isMenuItem: true,
    },
    {
        label: 'Client particulier',
        path: '/creer-client-particulier',
        icon: 'customers',
        name: pagesType.CUSTOMERS,
        component: CreateCustomerPP,
        exact: true,
    },
    {
        label: 'Catalogue produits',
        path: '/catalogue-produits',
        icon: 'products',
        name: pagesType.PRODUCTS,
        component: ProductCatalogue,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Catalogue produits',
        path: '/catalogue-produits/:uuid',
        icon: '',
        component: Product,
        name: pagesType.PRODUCTS,
        exact: true,
    },
    {
        label: 'Devis',
        path: '/devis',
        icon: 'quotations',
        name: pagesType.QUOTATIONS,
        component: Quotations,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Factures',
        isMenuItem: true,
        icon: 'invoices',
        name: pagesType.INVOICES,
        subComponents: [
            {
                label: 'Factures clients',
                path: '/factures',
                name: pagesType.INVOICES,
                component: Invoices,
                icon: '',
                exact: true,
            },
            {
                label: 'Factures obligés',
                path: '/factures-obliges',
                name: pagesType.DELEGATEE_INVOICES,
                component: DelegateeInvoices,
                icon: '',
                exact: true,
            },
        ],
    },
    {
        label: 'Factures',
        path: '/factures/:uuid',
        name: pagesType.INVOICES,
        component: Invoice,
        exact: true,
    },
    {
        label: 'Edition facture',
        path: '/factures/:uuid/edition',
        name: pagesType.INVOICES,
        component: InvoiceEdition,
    },
    {
        label: 'Apercu facture',
        path: '/factures/:uuid/apercu',
        name: pagesType.INVOICES,
        component: InvoicePreview,
    },
    {
        label: 'Factures obligés',
        path: '/factures-obliges/:id',
        name: pagesType.DELEGATEE_INVOICES,
        component: DelegateeInvoice,
        exact: true,
    },
    {
        label: 'Edition facture obligé',
        path: '/factures-obliges/:id/edition',
        name: pagesType.DELEGATEE_INVOICES,
        component: DelegateeInvoiceEdition,
    },
    {
        label: 'Apercu facture obligé',
        path: '/factures-obliges/:id/apercu',
        name: pagesType.DELEGATEE_INVOICES,
        component: DelegateeInvoicePreview,
    },

    {
        path: '/pas-d-acces',
        component: NoAccess,
        exact: true,
    },
    {
        label: 'Dossiers de primes',
        isMenuItem: true,
        icon: 'certificates',
        component: PrimeFolders,
        exact: true,
        path: '/dossiers-financement',
        name: pagesType.DOSSIERS_CEE,
    },
    {
        label: 'Ajout de documents',
        path: '/documents',
        name: pagesType.DOCUMENTS,
        icon: 'documents',
        component: Documents,
        isMenuItem: true,
        exact: true,
    },
    {
        label: 'Planning',
        path: '/planning',
        name: pagesType.PLANNING,
        icon: 'planning',
        component: Planning,
        isMenuItem: true,
        exact: true,
        beta: true,
    },
    {
        label: 'Fiche sous traitant',
        path: '/sous-traitants/:uuid',
        name: pagesType.SUB_CONTRACTORS,
        component: SubContractor,
    },
    {
        label: 'Profile',
        path: '/profil',
        component: Profile,
    },
    {
        label: 'Fiche client',
        path: '/clients/:uuid',
        name: pagesType.CUSTOMERS,
        component: Customer,
    },
    {
        label: 'Devis',
        path: '/devis/:uuid',
        name: pagesType.QUOTATIONS,
        component: Quotation,
        exact: true,
    },
    {
        label: 'Edition devis',
        path: '/devis/:uuid/edition',
        name: pagesType.QUOTATIONS,
        component: QuotationEdition,
    },
    {
        label: 'Apercu devis',
        path: '/devis/:uuid/apercu',
        name: pagesType.QUOTATIONS,
        component: QuotationPreview,
    },
    {
        label: 'Dossier financement',
        path: '/dossiers-financement/:uuid',
        name: pagesType.DOSSIERS_CEE,
        component: Prime,
    },
    {
        label: 'Parcelle cadastrale (Dossier financement)',
        path: '/dossiers-financement/:uuid/parcelle-cadastrale',
        name: 'PARCELLE CADASTRALE',
        component: CreateParcelleCadastrale,
    },
    {
        label: 'Ajouter un document',
        path: '/documents/ajouter-document',
        //name: pagesType.UPLOAD_DOCUMENTS,
        component: CreateUploadDocument,
    },
    {
        label: 'Ajouter un document',
        path: '/dossiers-financement/:uuid/upload-document/:type',
        //name: pagesType.UPLOAD_DOCUMENTS,
        component: CreateUploadDocument,
    },
    {
        label: 'Document',
        path: '/documents/:uuid',
        name: pagesType.DOCUMENTS,
        component: Document,
    },
    {
        label: 'Historique des mails',
        path: '/mails',
        component: MailsHistory,
    },
    /*{
        path: '/dossier-financement/:uuid/:key',
        component: PublicDossierCEE,
        isMenuItem: false,
        exact: true,
    },*/
];

const sidebarMenuItems = privateRoutes.filter((privateRoute) => privateRoute.isMenuItem).map(({ component, ...sidebarMenuItem }) => sidebarMenuItem);

const Login = loadable(() => import('./components/pages/Auth0Login'));
const Register = loadable(() => import('./components/pages/Register'));
const EmailValidation = loadable(() => import('./components/pages/EmailValidation'));
const RecoverPassword = loadable(() => import('./components/pages/RecoverPassword'));
const SetNewPassword = loadable(() => import('./components/pages/SetNewPassword'));
const AppointmentStatusRedirect = loadable(() => import('./components/pages/AppointmentStatusRedirect'));
const AppointmentConfirmation = loadable(() => import('./components/pages/appointmentConfirmation'));

const publicRoutes = [
    {
        path: '/connexion',
        component: Login,
    },
    {
        path: '/inscription-f9lo56ch21uwtz9',
        component: Register,
        exact: true,
    },
    {
        path: '/inscription-f9lo56ch21uwtz9/:token',
        component: Register,
    },
    {
        path: '/validation/:token',
        component: EmailValidation,
    },
    {
        path: '/recuperer-mot-de-passe',
        component: RecoverPassword,
    },
    {
        path: '/changement-de-mot-de-passe/:token',
        component: SetNewPassword,
    },
    {
        path: '/:role/appointment/:status/token/:token',
        component: AppointmentStatusRedirect,
    },
    {
        path: '/appointmentConfirmation',
        component: AppointmentConfirmation,
    },
    {
        path: '/maintenance',
        component: Maintenance,
    },
    {
        path: '/dossier-financement/:uuid/:key',
        component: PublicDossierCEE,
    },
    {
        path: '/document-signe',
        component: SignedDocument,
    },
    {
        path: '/signature',
        component: Signature,
    },
    {
        path: '/consentement-credit',
        exact: true,
        component: CreditFinancingConsentAccepted,
    },
    {
        path: '/consentement-credit/:financingConsentRequestId',
        component: CreditFinancingConsentRequest,
    },
];

const privateRoutesThatDontNeedAccountCompletion = ['/tableau-de-bord', '/profil'];

export { sidebarMenuItems, privateRoutes, publicRoutes, privateRoutesThatDontNeedAccountCompletion };
