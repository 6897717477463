import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import storage from '../../utils/storage';
import history from '../../utils/history';

const TypedRoute = ({ component: Component, type, isMenuItem, ...remainingProps }) => {
    const { organization } = useSelector((state) => state.organization);
    const { account } = useSelector((state) => state.auth);

    useEffect(() => {
        if (['dev', 'development', 'test'].includes(process.env.NODE_ENV)) return;
        if (organization && account) {
            mixpanel.people.set(
                {
                    $organization: organization.name,
                    $organizationId: organization._id,
                },
                () => mixpanel.track_pageview({ organization: organization.name, organizationId: organization._id, utilisateur: account.fullName })
            );
        }
    }, [organization, account]);

    useEffect(() => {
        if (!['/connexion'].includes(remainingProps.path) && organization && organization.accessOnlyToMPRPage) history.replace('/dossiers-financement');
    }, [organization, remainingProps.path]);

    const shouldRenderComponent =
        !storage.isAuthenticate() ||
        ['validation', '/dossier-financement/:uuid/:key', '/document-signe', '/signature', '/consentement-credit', '/consentement-credit/:financingConsentRequestId'].some((url) =>
            remainingProps.path.includes(url)
        );
    return (
        <Route
            {...remainingProps}
            render={(props) =>
                type === 'private' ? storage.isAuthenticate() ? <Component {...props} /> : <Redirect to='/connexion' /> : shouldRenderComponent ? <Component {...props} /> : <Redirect to='/' />
            }
        />
    );
};

TypedRoute.propTypes = {
    type: PropTypes.oneOf(['public', 'private', '']),
};

TypedRoute.defaultProps = {
    type: 'public',
};

export default TypedRoute;
